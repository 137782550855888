import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 80%;
  }
`;

const HeroWrapper = ({ children }) => {
  return <Container>{children}</Container>;
};

export default HeroWrapper;
