import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo';
import Nav from './nav/Nav';

const Container = styled.div`
  position: relative;
`;

const Slideshow = ({ layouts }) => {
  return (
    <Container id="slideshows">
      <div className="navContainer">
        <Nav data={layouts} />
      </div>
      <section>
        {layouts.map(({ node }) => {
          switch (node.layout) {
            case 'layout1':
              return (
                <React.Fragment key={node.id}>
                  <SlideOne layout={node} />
                </React.Fragment>
              );
            case 'layout2':
              return (
                <React.Fragment key={node.id}>
                  <SlideTwo layout={node} />
                </React.Fragment>
              );
            default:
              return 'wtf';
          }
        })}
      </section>
    </Container>
  );
};

export default Slideshow;
