import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  .left {
    position: relative;
  }

  .shape {
    width: 54%;
    position: absolute;
    right: 63%;
    top: 57%;
  }

  .imageContainer {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 70%;
    z-index: 10;
    margin-left: auto;
  }

  .right {
    padding-top: 6rem;
  }
`;

const Row3 = ({ videoImage3, videoImage4 }) => {
  const { greenXXX } = useStaticQuery(graphql`
    query {
      greenXXX: file(relativePath: { eq: "green-xxx.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Container className="row row3">
      <div className="left">
        <div className="shape">
          <Img fluid={greenXXX.childImageSharp.fluid} alt="abstract shapes" />
        </div>
        <div className="imageContainer">
          {videoImage3 && <VideoImage data={videoImage3} />}
        </div>
      </div>
      <div className="right">
        <div className="videoContainer">
          {videoImage4 && <VideoImage data={videoImage4} />}
        </div>
      </div>
    </Container>
  );
};

export default Row3;
