import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: calc((100% - 12rem) * 0.9);
  margin-right: auto;
  margin-left: 12rem;

  @media (min-width: 768px) {
    width: calc((100% - 12rem) * 0.9);
  }
`;

const Wrapper = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Wrapper;
