import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/Hero';
import Slideshow from '../components/Slideshow';

const IndexPage = (props) => {
  const { data } = props;
  const [heroData] = data.allContentfulHero.edges;
  const layouts = data.allContentfulLayout.edges;

  return (
    <Layout>
      <Hero heroData={heroData} />
      <Slideshow layouts={layouts} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  {
    allContentfulHero {
      edges {
        node {
          images {
            fluid(maxWidth: 489) {
              ...GatsbyContentfulFluid
            }
          }
          introText {
            raw
          }
          video {
            file {
              url
              contentType
            }
          }
        }
      }
    }

    allContentfulLayout(sort: { fields: order }) {
      edges {
        node {
          videoImage1 {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            file {
              url
              contentType
            }
          }
          videoImage2 {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            file {
              url
              contentType
            }
          }
          videoImage3 {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            file {
              url
              contentType
            }
          }
          videoImage4 {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            file {
              url
              contentType
            }
          }
          videoImage5 {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            file {
              url
              contentType
            }
          }
          videoImage6 {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            file {
              url
              contentType
            }
          }
          videoImage7 {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
            file {
              url
              contentType
            }
          }
          headline
          headline2
          imageCaption
          circleCaption
          paragraph1 {
            raw
          }
          paragraph2 {
            raw
          }
          theme
          title
          layout
          id
        }
      }
    }
  }
`;
