import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  .right {
    padding-top: 3rem;
    //display: flex;
    //justify-content: center;
    //align-items: center;
  }

  .circle {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    z-index: 10;
  }
`;

const Row1 = ({ headline, paragraph1, videoImage1 }) => {

  return (
    <Container className="row row1">
      <div className="left">
        <h2>{headline}</h2>
        <div className="textContainer">{renderRichText(paragraph1)}</div>
      </div>
      <div className="right">
        <div className="circle">
          {videoImage1 && <VideoImage data={videoImage1} />}
        </div>
      </div>
    </Container>
  );
};

export default Row1;
