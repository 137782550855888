import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Container = styled.nav`
  position: absolute;
  top: 5rem;
  left: 0;
  width: 12rem;
  padding: 0 0 0 2rem;
  z-index: 30;

  ul {
    margin: 0;
  }

  li {
    list-style: none;
  }

  a {
    font-family: 'Mark Pro', sans-serif;
    text-decoration: none;
    font-size: 0.6rem;
    line-height: 0;
    text-transform: uppercase;
    color: #8d8d8d;

    &:visited {
      color: #8d8d8d;
    }
  }

  li:first-child {
    a {
      font-weight: 600;
      color: #124350;
    }
  }
`;

const Nav = ({ data }) => {
  const nav = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(nav.current, {
      scrollTrigger: {
        trigger: '#slideshows',
        start: 'top top',
        onEnter: () => {
          gsap.set(nav.current, { position: 'fixed' });
        },
        onLeaveBack: () => {
          gsap.set(nav.current, { position: 'absolute' });
        },
      },
    });

    const handleLinkAnimations = (currentEle) => {
      data.forEach((ele) => {
        const id = ele.node.title.split(' ').join('').toLowerCase();

        if (id === currentEle && id === 'troublesometeens') {
          gsap.to(`#${id}-link`, {
            scale: 1.5,
            transformOrigin: 'left',
            duration: 0.3,
          });

          gsap.to(`#${id}-link a`, {
            fontWeight: 600,
            duration: 0.3,
            color: 'white',
          });
        } else if (id === currentEle) {
          gsap.to(`#${id}-link`, {
            scale: 1.5,
            transformOrigin: 'left',
            duration: 0.3,
          });

          gsap.to(`#${id}-link a`, {
            fontWeight: 600,
            duration: 0.3,
            color: '#124350',
          });
        } else {
          gsap.to(`#${id}-link`, {
            scale: 1,
            transformOrigin: 'left',
            duration: 0.3,
          });

          gsap.to(`#${id}-link a`, {
            fontWeight: 300,
            duration: 0.3,
            color: '#8D8D8D',
          });
        }
      });
    };

    data.forEach((ele) => {
      gsap.to('#slideshows', {
        scrollTrigger: {
          trigger: `#${ele.node.title.split(' ').join('').toLowerCase()}`,
          start: '-50px top',
          onEnter: (self) => {
            handleLinkAnimations(
              ele.node.title.split(' ').join('').toLowerCase()
            );
          },
          onEnterBack: (self) => {
            handleLinkAnimations(
              ele.node.title.split(' ').join('').toLowerCase()
            );
          },
        },
      });
    });
  }, [data]);

  return (
    <Container id="nav" ref={nav}>
      <ul>
        {data.map((ele) => {
          return (
            <li
              id={`${ele.node.title.split(' ').join('').toLowerCase()}-link`}
              key={ele.node.title}
            >
              <a href={`#${ele.node.title.split(' ').join('').toLowerCase()}`}>
                {ele.node.title}
              </a>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default Nav;
