import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import Img from 'gatsby-image';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  .left {
    padding-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageContainer {
      width: 100%;
    }
  }

  .right {
    display: flex;
    flex-direction: column;

    .imageRow {
      padding-left: 1rem;
      padding-top: 1rem;

      .imageContainer {
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        width: 65%;
        z-index: 10;
      }
    }
  }
`;

const Row1 = ({ headline, paragraph1, videoImage1, videoImage2 }) => {
  return (
    <Container className="row row1">
      <div className="left">
        <div className="imageContainer">
          {videoImage1 && <VideoImage data={videoImage1} />}
        </div>
      </div>
      <div className="right">
        <h2>{headline}</h2>
        <div className="textContainer">{renderRichText(paragraph1)}</div>
        <div className="imageRow">
          <div className="imageContainer">
            {videoImage2 && <VideoImage data={videoImage2} />}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Row1;
