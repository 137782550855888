import React, { useRef, useState, useEffect } from 'react';
import Img from 'gatsby-image';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import screenfull from 'screenfull';
import Play from '../../images/svg/play.svg';

const Container = styled.div`
  position: relative;

  .play {
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      width: 0.5rem;
    }
  }
`;

const VideoImage = ({ data }) => {
  const [play, setPlay] = useState(false);
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);
  const player = useRef(null);

  useEffect(() => {
    const imageData = data.find((ele) =>
      ele.file.contentType.includes('image')
    );

    const videoData = data.find((ele) =>
      ele.file.contentType.includes('video')
    );

    setImage(imageData);

    if (videoData) {
      setVideo(videoData);
    }
  }, [data]);

  const onClickFullscreen = (ele) => {
    screenfull.request(ele.current);
  };

  useEffect(() => {
    if (play) {
      onClickFullscreen(player);
    }
  }, [play]);

  useEffect(() => {
    screenfull.on('change', () => {
      if (!screenfull.isFullscreen) {
        setPlay(false);
      }
    });
  }, []);

  useEffect(() => {
    let videoContainer;

    if (video && play && player.current) {
      videoContainer = player.current;
      videoContainer.addEventListener('dblclick', () => {
        setPlay(false);
      });
    }

    return () => {
      if (video && play) {
        videoContainer.removeEventListener('dblclick', () => {
          setPlay(false);
        });
      }
    };
  }, [play, video]);

  return (
    <Container>
      {image && (
        <>
          <div
            onClick={() => {
              if (video) {
                setPlay(!play);
              }
            }}
          >
            <Img fluid={image.fluid} />
          </div>
          {video && (
            <button
              className="play"
              type="button"
              onClick={() => setPlay(!play)}
            >
              <Play />
            </button>
          )}
        </>
      )}
      {video && play && (
        <div className="player-wrapper" ref={player} id="videoContainer">
          <ReactPlayer
            url={video.file.url}
            controls
            width="100%"
            height="100%"
            playing={play}
            loop
          />
        </div>
      )}
    </Container>
  );
};

export default VideoImage;
