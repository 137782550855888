import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import Img from 'gatsby-image';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  .right {
    padding-top: 7rem;
  }

  padding-bottom: 6rem;
`;

const Row3 = ({ videoImage4, paragraph2 }) => {
  return (
    <Container className="row row3">
      <div className="left">
        <div className="imageContainer">
          {videoImage4 && <VideoImage data={videoImage4} />}
        </div>
      </div>
      <div className="right">
        {paragraph2 && (
          <div className="textContainer">{renderRichText(paragraph2)}</div>
        )}
      </div>
    </Container>
  );
};

export default Row3;
