import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import Arrow from '../../images/svg/circle-image-arrow.svg';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  .left {
    position: relative;
    display: flex;
    align-items: center;

    .arrowAndImage {
      position: relative;
      width: 100%;

      .arrowText {
        position: absolute;
        bottom: 95%;
        right: -25%;
        width: 80%;
        z-index: 20;
        padding-bottom: 0.5rem;

        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          white-space: normal;
        }

        span {
          display: block;
        }
      }
    }

    .shape {
      position: absolute;
      top: -12%;
      right: 85%;
      width: 60%;
    }

    .imageContainer {
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      width: 100%;
      z-index: 10;
    }
  }

  .arrowText {
    svg path {
      fill: ${(props) => props.variant.fill};
    }
  }
`;

const Row4 = ({ videoImage5, videoImage6, circleCaption, theme }) => {
  const { orangeMustard } = useStaticQuery(graphql`
    query {
      orangeMustard: file(relativePath: { eq: "orange-mustard.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const variants = {
    warm: {
      fill: '#124350',
    },
    green: {
      fill: 'white',
    },
  };

  return (
    <Container className="row row4" variant={variants[theme]}>
      <div className="left">
        <div className="arrowAndImage">
          <div className="imageContainer">
            {videoImage5 && <VideoImage data={videoImage5} />}
          </div>
          <div className="arrowText">
            <p>{circleCaption}</p>
            <Arrow />
          </div>
        </div>
        <div className="shape">
          <Img
            fluid={orangeMustard.childImageSharp.fluid}
            alt="abstract shapes"
          />
        </div>
      </div>
      <div className="right">
        <div className="imageContainer">
          {videoImage6 && <VideoImage data={videoImage6} />}
        </div>
      </div>
    </Container>
  );
};

export default Row4;
