import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  .right {
    padding: 0 2rem;
    position: relative;
    overflow: hidden;
  }

  .finalText {
    font-size: 4vw;
    width: 75%;
    line-height: 1.2;
    margin: 0;
    padding: 8vw 0 8vw 0;
  }

  .shape {
    position: absolute;
    bottom: -5%;
    right: 0;
    width: 20%;

    .gatsby-image-wrapper {
      padding-bottom: 10%;
    }
  }
`;

const Row5 = ({ videoImage7, headline2 }) => {
  const { brownTan } = useStaticQuery(graphql`
    query {
      brownTan: file(relativePath: { eq: "brown-tan-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container className="row row5">
      <div className="left">
        <div className="imageContainer">
          {videoImage7 && <VideoImage data={videoImage7} />}
        </div>
      </div>
      <div className="right">
        <p className="finalText">{headline2}</p>
        <div className="shape">
          <Img fluid={brownTan.childImageSharp.fluid} alt="abstract shapes" />
        </div>
      </div>
    </Container>
  );
};

export default Row5;
