import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  .left {
    position: relative;

    .imageShapeContainer {
      margin: 0 auto 16vw 0;
      width: 150%;
      transform: translate(-4rem, calc(-25% / 1.5));
      //margin-bottom: 14vw;
    }

    .imageContainer {
      border-radius: 50%;
      overflow: hidden;
      width: 100%;
    }
  }

  .right {
    padding: 7.5rem 7rem 11rem 5rem;
    position: relative;
  }

  .triangleSquiggle {
    width: 72%;
    position: absolute;
    top: 86%;
    left: 0;
  }

  .brownCircle {
    position: absolute;
    width: 10%;
    right: 0;
    top: 20%;
  }

  .archBottom {
    position: absolute;
    width: 15%;
    bottom: 0;
    right: 20%;
  }
`;

const Row5 = ({ videoImage7, paragraph2 }) => {
  const { archBottom, brownCircle, triangleSquiggle } = useStaticQuery(graphql`
    query {
      archBottom: file(relativePath: { eq: "arch-bottom.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brownCircle: file(relativePath: { eq: "brown-circle.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      triangleSquiggle: file(relativePath: { eq: "triangle-squiggle.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container className="row row5">
      <div className="left">
        <div className="imageShapeContainer">
          <div className="imageContainer">
            {videoImage7 && <VideoImage data={videoImage7} />}
          </div>
          <div className="triangleSquiggle">
            <Img
              fluid={triangleSquiggle.childImageSharp.fluid}
              alt="abstract shapes"
            />
          </div>
        </div>
      </div>
      <div className="right">
        {paragraph2 && (
          <div className="textContainer">{renderRichText(paragraph2)}</div>
        )}
        <div className="brownCircle">
          <Img
            fluid={brownCircle.childImageSharp.fluid}
            alt="abstract shapes"
          />
        </div>
        <div className="archBottom">
          <Img fluid={archBottom.childImageSharp.fluid} alt="abstract shapes" />
        </div>
      </div>
    </Container>
  );
};

export default Row5;
