import React from 'react';
import styled from 'styled-components';
import Wrapper from '../common/Wrapper';
import Row1 from './Row1';
import Row2 from './Row2';
import Row3 from './Row3';
import Row4 from './Row4';
import Row5 from './Row5';

const Container = styled.section`
  background-color: ${(props) => props.variant.backgroundColor};
  padding: 5rem 0 0 0;

  p {
    color: ${(props) => props.variant.color};
  }

  h2 {
    color: ${(props) => props.variant.color};
  }

  h1 {
    font-size: 4.5rem;
    font-weight: 300;

    span {
      display: block;
    }
  }

  h2 {
    width: 14rem;
  }

  .row {
    display: flex;
    min-height: 20rem;
    justify-content: space-between;
  }

  .row1,
  .row2,
  .row3 {
    .left {
      width: 50%;
    }

    .right {
      width: 40%;
    }
  }

  .row4,
  .row5 {
    .left {
      width: 27.5%;
    }

    .right {
      width: 62.5%;
    }
  }
`;

const SlideOne = (props) => {
  const { layout } = props;

  const {
    headline,
    headline2,
    paragraph1,
    paragraph2,
    theme,
    imageCaption,
    circleCaption,
    videoImage1,
    videoImage2,
    videoImage3,
    videoImage4,
    videoImage5,
    videoImage6,
    videoImage7,
    title,
  } = layout;

  const variants = {
    warm: {
      backgroundColor: '#FEF6EF',
      color: '#124350',
    },
    green: {
      backgroundColor: '#124350',
      color: 'white',
    },
  };

  return (
    <Container
      id={title.split(' ').join('').toLowerCase()}
      variant={variants[theme]}
    >
      <Wrapper>
        <Row1
          headline={headline}
          paragraph1={paragraph1}
          videoImage1={videoImage1}
        />
        <Row2
          videoImage2={videoImage2}
          videoImage3={videoImage3}
          imageCaption={imageCaption}
          theme={theme}
        />
        <Row3 videoImage4={videoImage4} paragraph2={paragraph2} />
        <Row4
          videoImage5={videoImage5}
          videoImage6={videoImage6}
          circleCaption={circleCaption}
          theme={theme}
        />
        <Row5 videoImage7={videoImage7} headline2={headline2} />
      </Wrapper>
    </Container>
  );
};

export default SlideOne;
