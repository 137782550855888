import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import Arrow from '../../images/svg/small-image-arrow.svg';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  .left {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .right {
    padding-top: 5rem;
  }

  .col1,
  .col2 {
    width: 50%;
  }

  .col1 {
    position: relative;

    .imageContainer {
      width: 70%;
      position: absolute;
      top: 0;
      left: 0;
=    }
  }

  .col2 {
    .imageContainer {
      position: relative;
    }
    
   

    .arrowText {
      position: absolute;
      top: 75%;
      right: 57%;

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .arrowText {
    svg path {
      fill: ${(props) => props.variant.fill};
    }
  }
 
  
`;

const Row2 = ({ videoImage2, videoImage3, imageCaption, theme }) => {
  const { tanGreen } = useStaticQuery(graphql`
    query {
      tanGreen: file(relativePath: { eq: "tan-green.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const variants = {
    warm: {
      fill: '#124350',
    },
    green: {
      fill: 'white',
    },
  };

  return (
    <Container className="row row2" variant={variants[theme]}>
      <div className="left">
        <div className="col1">
          <div className="imageContainer">
            <Img fluid={tanGreen.childImageSharp.fluid} alt="abstract shapes" />
          </div>
        </div>
        <div className="col2">
          <div className="imageContainer">
            {videoImage2 && <VideoImage data={videoImage2} />}
            <div className="arrowText">
              <Arrow />
              <p>{imageCaption}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="imageContainer">
          {videoImage3 && <VideoImage data={videoImage3} />}
        </div>
      </div>
    </Container>
  );
};

export default Row2;
