import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import HeroWrapper from './common/HeroWrapper';

const Container = styled.section`
  padding-bottom: 5rem;
  position: relative;
  overflow: hidden;

  .titleContainer {
    position: relative;
  }

  h1 {
    font-size: ${(props) => props.theme.fontSizes.xl};
    font-weight: 300;

    span {
      display: block;
    }
  }

  h3 {
    font-size: ${(props) => props.theme.fontSizes.m};

    span {
      display: block;
    }
  }

  .top,
  .bottom {
    display: flex;
    min-height: 60vh;
  }

  .left {
    width: 60%;
  }

  .right {
    width: 40%;
  }

  .top {
    .left {
      display: flex;
      justify-content: center;
      align-items: center;

      .titleContainer {
        max-width: 25rem;
      }
    }

    .right {
      //.gatsby-image-wrapper {
      //  padding-bottom: calc(100% * 2 / 3);
      //}
    }
  }

  .bottom {
    .left {
      .imageContainer {
        width: 80%;
        padding-top: 5rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;

      button {
        display: inline;
        background-color: transparent;
        border: none;
        font-weight: 500;
        margin: 0;
        padding: 0;
        font-family: 'Mark Pro', sans-serif;
        text-decoration: underline;
      }
    }
  }

  .shapeLeft {
    position: absolute;
    top: 7rem;
    left: 0;
    width: 12%;
  }

  .shapeArch {
    position: absolute;
    top: 4rem;
    right: 0;
    width: 28%;
  }

  .shapeBottomRight {
    position: absolute;
    bottom: -8%;
    right: 5%;
    width: 31%;
  }
`;

const Hero = ({ heroData }) => {
  const [play, setPlay] = useState(false);
  const [video, setVideo] = useState(null);
  const player = useRef(null);

  const { heroLeft, heroBottomRight, heroArch } = useStaticQuery(graphql`
    query {
      heroLeft: file(relativePath: { eq: "hero-left.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroBottomRight: file(relativePath: { eq: "hero-bottom-right.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroArch: file(relativePath: { eq: "hero-arch.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { video: videoData } = heroData.node;

  useEffect(() => {
    if (videoData) {
      setVideo(videoData);
    }
  }, [videoData]);

  const onClickFullscreen = (ele) => {
    screenfull.request(ele.current);
  };

  useEffect(() => {
    if (play) {
      onClickFullscreen(player);
    }
  }, [play]);

  useEffect(() => {
    screenfull.on('change', () => {
      if (!screenfull.isFullscreen) {
        setPlay(false);
      }
    });
  }, []);

  useEffect(() => {
    let videoContainer;

    if (video && play && player.current) {
      videoContainer = player.current;
      videoContainer.addEventListener('dblclick', () => {
        setPlay(false);
      });
    }

    return () => {
      if (video && play) {
        videoContainer.removeEventListener('dblclick', () => {
          setPlay(false);
        });
      }
    };
  }, [play, video]);

  return (
    <Container>
      <div className="shapeLeft">
        <Img fluid={heroLeft.childImageSharp.fluid} alt="abstract shapes" />
      </div>
      <div className="shapeBottomRight">
        <Img
          fluid={heroBottomRight.childImageSharp.fluid}
          alt="abstract shapes"
        />
      </div>
      <HeroWrapper>
        <div className="top">
          <div className="left">
            <div className="titleContainer">
              <div className="shapeArch">
                <Img
                  fluid={heroArch.childImageSharp.fluid}
                  alt="abstract shapes"
                />
              </div>
              <h1>
                <span>In the</span>
                <span>life of</span>
                Amanda.
              </h1>
              <h3>
                Tall tales and short stories from your
                <span>favourite humans.</span>
              </h3>
            </div>
          </div>
          <div className="right">
            <div className="imageContainer">
              <Img fluid={heroData.node.images[0].fluid} />
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <div className="imageContainer">
              <Img fluid={heroData.node.images[1].fluid} />
            </div>
          </div>
          <div className="right">
            <div className="textContainer">
              {/*{renderRichText(heroData.node.introText)} */}
              <p>
                We get it, turning 30 can be a hurricane of emotions. It’s a
                milestone year that’s solidifies you into adulthood, which
                doesn’t sound so bad until it sinks in and you realize wow, now
                you’re an adult. But rather than stress about all the moments
                past, we’d rather celebrate them with a little help from your
                friends. Sit down, pour a bottle of wine and start your
                adventure with&nbsp;
                <button type="button" onClick={() => setPlay(!play)}>
                  this video.
                </button>
              </p>
              {video && play && (
                <div
                  className="player-wrapper"
                  ref={player}
                  id="videoContainer"
                >
                  <ReactPlayer
                    url={video.file.url}
                    controls
                    width="100%"
                    height="100%"
                    playing={play}
                    loop
                  />
                </div>
              )}
              <h4>Now, have a little scroll.</h4>
            </div>
          </div>
        </div>
      </HeroWrapper>
    </Container>
  );
};

export default Hero;
