import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import Arrow from '../../images/svg/circle-image-arrow.svg';
import VideoImage from '../common/VideoImage';

const Container = styled.div`
  padding-top: 5rem;

  .left {
    display: flex;
    justify-content: center;

    .imageContainer {
      width: 100%;
    }
  }

  .right {
    padding-top: 3rem;
    display: flex;
    justify-content: flex-end;

    .imageContainer {
      width: 100%;
    }
  }
`;

const Row4 = ({ videoImage5, videoImage6 }) => {
  return (
    <Container className="row row4">
      <div className="left">
        <div className="imageContainer">
          {videoImage5 && <VideoImage data={videoImage5} />}
        </div>
      </div>
      <div className="right">
        <div className="imageContainer">
          {videoImage6 && <VideoImage data={videoImage6} />}
        </div>
      </div>
    </Container>
  );
};

export default Row4;
